import {persianDigit} from "../../../../logic/core";
import "./style.css";
import {useGetGeneralContext} from "../../../../logic/context/general_context";
import {useTranslate} from "../../../../logic/hooks/general_hook";

const RangeQuestion = ({info, answerSetter, answer}) => {
    const {lang} = useGetGeneralContext();
    const {t} = useTranslate();
    const setAnswer = (answer) => {
        answerSetter(+answer);
    }
    return (
        <div className="range-question ltr">
            <div className="options">
                {
                    info.answers["0"] === 0 && <div className="d-flex not-used">
                        <button
                            className={[answer === 0 ? "active" : ""].join(" ")}
                            onClick={() => setAnswer(0)}>
                            {t("notUsed", lang)}
                        </button>
                    </div>
                }
                {
                    Object.entries(info.answers).map(([key, value]) => {
                        if (value > 0) return (
                            <button key={key} onClick={() => {
                                setAnswer(key)
                            }}
                                    className={["option", +key <= +answer ? "active" : ""].join(" ")}
                            >
                                {persianDigit(value, lang)}
                            </button>
                        )
                    })
                }
                <div className="desc rtl">
                    <p className="small">{t("select-point", lang)}</p>
                    {/*<span className="small max">{t("good-label",lang)}</span>*/}
                    {/*<span className="small min">{t("bad-label",lang)}</span>*/}
                </div>
            </div>
        </div>
    )
}
export default RangeQuestion;
import {useEffect, useState} from "react";
import config from "./../statics/config.json";

import {get_customer_info, get_survey_question, set_survey_answers} from "../api/api_survey";

export function useGetUserInfo(token, opt = {onSuccess: null, onError: null, onFail: null}) {
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState(null);

    const getCustomer = () => {
        setLoading(true);
        get_customer_info(token).then(({body, status, error}) => {
            if (status === 200) {
                setResult(body);
                opt && opt.onSuccess && opt.onSuccess({body, status, error});
            } else {
                opt && opt.onError && opt.onError({body, status, error});
            }
            setLoading(false);
        })
            .catch(err => {
                opt && opt.onFail && opt.onFail(err);
                setLoading(false);
            })
    }

    useEffect(() => {
        getCustomer();
    }, []);

    return {result, loading}
}

export function useGetQuestions(token) {
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState(null);
    const testInfo = [
        {
            "questionId": 1,
            "question": "چگونه بود کیفیت رستوران؟",
            "questionEN": "how are you ?",
            "question_type": "rate",
            "answers": {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7,
                "8": 8,
                "9": 9,
                "10": 10
            },
            "max_select": "1",
            "requirement": "1"
        },
        {
            "questionId": 2,
            "question": "تا چه اندازه تمایل داررید مارا به دیگران پیشنهاد دهید؟",
            "questionEN": "how are you ?",
            "question_type": "rate",
            "answers": {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7,
                "8": 8,
                "9": 9,
                "10": 10
            },
            "max_select": "1",
            "requirement": "1"
        },
        {
            "questionId": 3,
            "question": "توضیح بده چرا خوب نبودیم؟",
            "questionEN": "how are you ?",
            "question_type": "description",
            "answers": [],
            "max_select": "200",
            "requirement": "1"
        }
    ];

    useEffect(() => {
        setResult(testInfo);
        setLoading(false);
    }, []);

    return {result, loading}

}

export function useHandleSurvey(token, opt = {onSuccess: null, onError: null, onFail: null}) {
    const [currentQuestionId, setCurrentQuestionId] = useState(0)
    const [answers, setAnswers] = useState(null);
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState(null);
    const [errors, setErrors] = useState(null);

    const getSurveyQuestion = () => {
        get_survey_question(token).then(({status, body, error}) => {
            if (status === 200) {
                setResult(body.questions);
                opt && opt.onSuccess && opt.onSuccess({body, status, error});
                const answers = initAnswers(body.questions);
                setAnswers(answers);
            } else {
                setResult(body)
                opt && opt.onError && opt.onError({body, status, error});
            }
            setLoading(false);
        })
            .catch(err => {
                opt && opt.onFail && opt.onFail(err);
                setLoading(false);
            })
    }

    useEffect(() => {
        getSurveyQuestion();
    }, []);

    const initAnswers = (info) => {
        let answers = {};
        info.map(row => {
            answers[row.questionId] = null
        })
        return answers;
    }

    const nextQuestion = (glen) => {
        const {requirement, questionId} = result[currentQuestionId]
        if (+requirement === 1 && answers[questionId] ===null) {
            let err = {};
            err[currentQuestionId] = "isReq"
            setErrors(err);
            return;
        }
        if (currentQuestionId < (glen - 1)) {
            setCurrentQuestionId(currentQuestionId + 1);
        }
    }

    const prevQuestion = () => {
        if (currentQuestionId > 0) {
            setCurrentQuestionId(currentQuestionId - 1);
        }
    }

    const setAnswer = (qid, answer) => {
        let newAnswer = {}
        newAnswer[qid.toString()] = answer;
        setAnswers(prevState => ({...prevState, ...newAnswer}))
        setErrors(null);

    }

    const submit = (opt = {onSuccess: null, onError: null, onFail: null}) => {
        setLoading(true);
        set_survey_answers(token, answers).then(res => {
            if (res.status === 200) {
                opt.onSuccess && opt.onSuccess(res);
            } else {
                opt.onError && opt.onError(res);
            }
            setLoading(false);
        })
            .catch(err => {
                opt.onFail && opt.onFail(err);
                setLoading(false);
            })
    }

    return {result, errors, loading, currentQuestionId, submit, nextQuestion, prevQuestion, answers, setAnswer}
}

